<template>
  <!-- Top bar week status data. -->
  <div v-if="globalWeekData && !isProduction" class="week-status">
    <div>
      <span style="font-weight: 500">{{ $t('WEEK_STATUS') }}:</span>&nbsp;<span style="font-weight: 700">{{ $t('WEEK_OF_MM_STATUS.' + globalWeekData.status) }}</span>&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;&nbsp;
      <template v-if="globalWeekData.currentDay"><span style="font-weight: 500">{{ $t('CURRENT_DAY') }}:</span>&nbsp;{{ globalWeekData.currentDay }} (van de {{ globalWeekData.weekDurationInDays }} dagen)&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;&nbsp;</template>
      <span style="font-weight: 500">{{ $t('START_DATE') }}:</span>&nbsp;{{ new Date(globalWeekData.startDate).toLocaleString('nl') }}&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;&nbsp;
      <span style="font-weight: 500">{{ $t('END_DATE') }}:</span>&nbsp;{{ new Date(globalWeekData.endDate).toLocaleString('nl') }}&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;&nbsp;
      <span style="font-weight: 500">Rangschikking datum:</span>&nbsp;{{ new Date(globalWeekData.rankingDate).toLocaleString('nl') }}
    </div>
  </div>
  <!-- Shared layout between the CMS, Helpdesk and Teacher Portal. -->
  <main class="main-layout" :class="globalWeekData && !isProduction ? 'contains-week-top-bar' : ''">
    <div class="page-layout-wrapper"><!-- Sidebar -->
      <SidebarMenu v-if="!hideSidebar" :menu-items="menuItems" :sidebar-logo="sidebarLogo"/>

      <!-- Page content -->
      <div class="page-content-wrapper">
        <div class="page-content scrollable" :class="hideSidebar ? 'without-sidebar' : 'with-sidebar'">
          <router-view/>
          <Toast/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SidebarMenu from '@/components/partials/SidebarMenu'
import MenuItem from '@/models/menu/MenuItem'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { useRoute, useRouter } from 'vue-router'
import Menu from '@/models/menu/Menu'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import { NAVBAR_NAMES } from '@/utils/language/nl.json'
import { computed, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { ROUTE_NAMES_PROFESSIONAL } from '@/router/modules/professional'
import Toast from '@/components/elements/Toast'
import { GET_WEEK_DATA } from '@/store/actions'
import { useI18n } from 'vue-i18n'
// import { ref } from 'vue'
import { ACTIVE_WEEK_STATUS } from '@/utils/data/weekStatus'

export default {
  name: 'Layout',
  components: {
    SidebarMenu,
    Toast,
    // DateSelector,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    // Get the active platform.
    const platform = route.meta.platform
    const hideSidebar = route.meta.hideSidebar
    store.dispatch(GET_WEEK_DATA)

    // const isCMS = computed(() => route.fullPath.startsWith('/cms'))

    const globalWeekData = computed(() => store.getters.getWeekData)
    const isProduction = computed(() => process.env.VUE_APP_IS_PRODUCTION === 'production')

    const { t } = useI18n()

    const profile = computed(() => store.getters.getTeacherProfile)

    // The menu items depend on the type of the platform (CMS/Helpdesk/Teacher Portal).
    // First we define all the menus together.
    const menus = {
      teacherPortal: [
        // TODO: Add the translations to the menu item name strings.

        // Subcategory - Algemeen
        Menu.create('algemeen', 'ALGEMEEN', [
          new MenuItem(1, require('../assets/icons/icn_overview.svg'), NAVBAR_NAMES.OVERVIEW, ROUTE_NAMES_TEACHER_PORTAL.OVERVIEW, []),
          new MenuItem(2, require('../assets/icons/icn_overview.svg'), profile.value.isApproved ? NAVBAR_NAMES.OVERVIEW : NAVBAR_NAMES.WAITINGROOM, ROUTE_NAMES_TEACHER_PORTAL.WAITING_ROOM, []),
          new MenuItem(3, require('../assets/icons/icn_ranking.svg'), NAVBAR_NAMES.SCORE_PAST, ROUTE_NAMES_TEACHER_PORTAL.RANKING, []),
          new MenuItem(4, require('../assets/icons/icn_instruction.svg'), NAVBAR_NAMES.INSTRUCTIONS, ROUTE_NAMES_TEACHER_PORTAL.INSTRUCTIONS, []),
          new MenuItem(5, require('../assets/icons/icn_prize.svg'), NAVBAR_NAMES.PRICES, ROUTE_NAMES_TEACHER_PORTAL.PRIZES, [], { external: true }),
        ]),

        // Menu.create('Leshareriaal', 'LEHARERIAAL', [
        //   new MenuItem(11, require('../assets/icons/icn_conversation.svg'), NAVBAR_NAMES.CONVERSATION, ROUTE_NAMES_TEACHER_PORTAL.CONVERSATION, [])
        // ]),

        // Subcategory - Beheer
        Menu.create('beheer', 'BEHEER', [
          new MenuItem(21, require('../assets/icons/icn_personsettings.svg'), NAVBAR_NAMES.MY_SCHOOL, ROUTE_NAMES_TEACHER_PORTAL.MY_SCHOOL,
            [ROUTE_NAMES_TEACHER_PORTAL.EDIT_TEACHER]),
          new MenuItem(22, require('../assets/icons/icn_class.svg'), NAVBAR_NAMES.ALL_CLASSES, ROUTE_NAMES_TEACHER_PORTAL.MY_CLASSES,
            [
              ROUTE_NAMES_TEACHER_PORTAL.EDIT_CLASS,
              ROUTE_NAMES_TEACHER_PORTAL.CREATE_CLASS
            ]),
          //new MenuItem(4, require('../assets/icons/icn_settings.svg'), 'Test Components', ROUTE_NAMES_TEACHER_PORTAL.TEST, []),
          new MenuItem(23, require('../assets/icons/icn_settings.svg'), NAVBAR_NAMES.MY_ACCOUNT, ROUTE_NAMES_TEACHER_PORTAL.SETTINGS,
            [ROUTE_NAMES_TEACHER_PORTAL.EDIT_DETAILS]
          ),
          new MenuItem(24, require('../assets/icons/icn_help.svg'), NAVBAR_NAMES.HELP, ROUTE_NAMES_TEACHER_PORTAL.HELP, []),
        ]),
      ],
      helpdesk: [
        // No subcategories
        Menu.create('helpdesk', '', [
          new MenuItem(20, require('../assets/icons/icn_school.svg'), 'Scholen', ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW,
            [
              ROUTE_NAMES_HELPDESK.SCHOOL_DETAILS,
              ROUTE_NAMES_HELPDESK.SCHOOL_EDIT,
              ROUTE_NAMES_HELPDESK.SCHOOL_CREATE
            ]),
          new MenuItem(21, require('../assets/icons/icn_class.svg'), 'Klassen', ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW, [
            ROUTE_NAMES_HELPDESK.CLASS_DETAILS,
            ROUTE_NAMES_HELPDESK.CLASS_CREATE,
            ROUTE_NAMES_HELPDESK.CLASS_EDIT,
          ]),
          new MenuItem(25, require('../assets/icons/icn_friends.svg'), 'Leraren', ROUTE_NAMES_HELPDESK.TEACHERS_OVERVIEW, [
            ROUTE_NAMES_HELPDESK.TEACHER_DETAILS,
            ROUTE_NAMES_HELPDESK.TEACHER_CREATE,
            ROUTE_NAMES_HELPDESK.TEACHER_EDIT,
          ]),
          new MenuItem(26, require('../assets/icons/icn_friends.svg'), 'Professionals', ROUTE_NAMES_HELPDESK.PROFESSIONALS_OVERVIEW, []),
          new MenuItem(27, require('../assets/icons/icn_ranking.svg'), 'Ranglijst', ROUTE_NAMES_HELPDESK.RANKING, []),
          new MenuItem(28, require('../assets/icons/icn_stats.svg'), 'Statistieken', ROUTE_NAMES_HELPDESK.STATS, []),
          new MenuItem(29, require('../assets/icons/icn_friends.svg'), 'Bulkactie', ROUTE_NAMES_HELPDESK.BULK_EDIT, []),
        ]),
      ],
      cms: [
        // Subcategory - Beheer
        Menu.create('beheer', 'BEHEER', [
          new MenuItem(30, require('../assets/icons/icn_mission.svg'), 'Missies', ROUTE_NAMES_CMS.MISSIONS,
            [
              ROUTE_NAMES_CMS.CREATE_MISSION,
              ROUTE_NAMES_CMS.MISSION_DETAILS,
              ROUTE_NAMES_CMS.CREATE_DIA
            ]),
          new MenuItem(31, require('../assets/icons/icn_mission.svg'), 'Missie Videos', ROUTE_NAMES_CMS.MISSION_VIDEOS,
            [
              ROUTE_NAMES_CMS.MISSION_VIDEOS_CREATE,
            ]),
          new MenuItem(32, require('../assets/icons/icn_tags.svg'), t('SIDE_MENU.TAGS'), ROUTE_NAMES_CMS.TAGS, []),
          new MenuItem(33, require('../assets/icons/icn_puzzle.svg'), t('SIDE_MENU.EXTRA_GAMES'), ROUTE_NAMES_CMS.EXTRAS,
            [
              ROUTE_NAMES_CMS.EDIT_EXTRA_GAME,
              ROUTE_NAMES_CMS.CREATE_EXTRA_GAME
            ]),
          new MenuItem(34, require('../assets/icons/icn_mediaquiz.svg'), t('SIDE_MENU.MEDIAQUIZ'), ROUTE_NAMES_CMS.MEDIAQUIZ, [
            ROUTE_NAMES_CMS.CREATE_MEDIAQUIZ
          ]),
          new MenuItem(35, require('../assets/icons/icn_instruction.svg'), t('SIDE_MENU.INSTRUCTIONS'), ROUTE_NAMES_CMS.INSTRUCTIONS, [
            ROUTE_NAMES_CMS.CREATE_INSTRUCTIONS
          ]),
          /*new MenuItem(35, require('../assets/icons/icn_prize.svg'), t('SIDE_MENU.PRIZES'), ROUTE_NAMES_CMS.PRIZES,
            [
              ROUTE_NAMES_CMS.CREATE_PRIZE
            ]),*/
          new MenuItem(36, require('../assets/icons/icn_faq.svg'), t('SIDE_MENU.FAQ'), ROUTE_NAMES_CMS.FAQ,
            [
              ROUTE_NAMES_CMS.CREATE_FAQ,
              ROUTE_NAMES_CMS.EDIT_FAQ
            ]),
          new MenuItem(37, require('../assets/icons/icn_settings.svg'), 'Week van MM', ROUTE_NAMES_CMS.WEEK_OF_MM_MANAGER,
            []
          ),
          new MenuItem(38, require('../assets/icons/icn_settings.svg'), 'Status bericht', ROUTE_NAMES_CMS.STATUS_MESSAGE,
            []
          ),
        ]),
      ],
      professional: [
        Menu.create('professional', '', [
          new MenuItem(44, require('../assets/icons/icn_overview.svg'), profile.value.isApproved ? NAVBAR_NAMES.OVERVIEW : NAVBAR_NAMES.WAITINGROOM, profile.value.isApproved ? ROUTE_NAMES_PROFESSIONAL.OVERVIEW : ROUTE_NAMES_PROFESSIONAL.WAITING_ROOM, []),
          new MenuItem(41, require('../assets/icons/icn_instruction.svg'), NAVBAR_NAMES.INSTRUCTIONS, ROUTE_NAMES_PROFESSIONAL.INSTRUCTIONS, []),
          new MenuItem(42, require('../assets/icons/icn_help.svg'), NAVBAR_NAMES.HELP, ROUTE_NAMES_PROFESSIONAL.HELP, []),
          new MenuItem(43, require('../assets/icons/icn_settings.svg'), NAVBAR_NAMES.MY_ACCOUNT, ROUTE_NAMES_PROFESSIONAL.SETTINGS,
            [ROUTE_NAMES_PROFESSIONAL.EDIT_DETAILS]
          ),
        ])
      ]
    }

    // console.log('is approved', profile.value.isApproved)

    addMeta(menus)

    function addMeta(menus) {
      const routes = router.getRoutes()
      for (const menuKey in menus) {
        const menu = menus[menuKey]
        for (let i = 0; i < menu.length; i++) {
          const subMenu = menu[i]

          for (let j = 0; j < subMenu.items.length; j++) {
            const menuItem = subMenu.items[j]
            const route = routes.find(route => route.name === menuItem.routeName)
            if (route) {
              menuItem.addMeta(route.meta)
            } else {
              console.log("couldn't find", menuItem)
            }
          }
        }
      }
    }

    watch(() => globalWeekData.value, (curr, prev) => {
      if (curr) {
        if (globalWeekData.value && globalWeekData.value.status === ACTIVE_WEEK_STATUS) {
          menus.teacherPortal[0].items[2].changeDisplayName(NAVBAR_NAMES.SCORE_DURING)
        }
      }
    })

    const sidebarLogos = {
      teacherPortal: require('../assets/icons/icn_logo_full.svg'),
      helpdesk: require('../assets/icons/icn_helpdesk.svg'),
      cms: require('../assets/icons/icn_logo_cms.svg'),
      professional: require('../assets/icons/icn_logo_full.svg')
    }

    return {
      // We return the correct menu, based on the platform.
      menuItems: menus[platform],
      sidebarLogo: sidebarLogos[platform],
      hideSidebar,
      globalWeekData,
      isProduction,

      // isCMS,
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

$week-status-bar-height: rem(60);

.main-layout {
  height: 100%;

  .page-layout-wrapper {
    display: flex;
    height: 100%;
  }

  &.contains-week-top-bar {
    padding-top: $week-status-bar-height;
  }
}

.page-content-wrapper {
  background-color: var(--gray_light);
  border-radius: rem(20) 0 0 rem(20);
  width: 100%;
  flex-shrink: 1;
  .page-content {
    overflow-y: auto;
    overflow-x: auto;
    // padding: rem(32) rem(49);
    position: relative;
    height: 100%;
  }

  .content-container {
    padding: rem(32) rem(49);
  }
}

@media screen and (min-width: 1920px) {
  .page-content {
    margin: 0 auto;
  }
}

.week-status {
  position: absolute;
  height: $week-status-bar-height;
  top: 0;
  //left: 0;
  //right: 0;
  font-size: rem(16);
  width: 100%;
  background-color: #cce5ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(14) rem(14) rem(14) rem(20);
  color: #004085;

  .set-week {
    display: flex;
    gap: rem(8);
    align-items: center;

    button {
      height: fit-content;
      white-space: nowrap;
      display: block;
    }
  }
}

.without-sidebar{
  width: 100vw;
  padding: 0;
  border-radius: 0;
}

.no-results-teacher {
  @include position(absolute, $top: 50%, $left: 50%);
  transform: translate(-50%, -50%);
}

/** Text **/
.page-title {
  margin-bottom: rem(32);
}

.page-section-title {
  font-size: rem(24);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(28);
  margin-bottom: rem(11);
}

.section-title{
  margin-bottom: rem(32);
}

.page-section-text {
  color: #8187A7;
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(24);
  margin-bottom: rem(11);
}

/** Buttons **/
.action-button-group {
  display: flex;
  gap: rem(16);
  justify-content: flex-start;
}

/** Forms **/
.page-form {
  margin-top: rem(32);
  width: rem(492);

  .form-element {
    &:not(:last-of-type) {
      margin-bottom: rem(28);
    }
  }

  .submit-button {
    margin-top: rem(40);
  }
}

.two-column-wrapper {
  display: flex;

  .form-element-column {
    flex: 50%;

    &:not(:last-of-type) {
      margin-right: rem(10);
    }

    &:last-of-type {
      margin-left: rem(10);
    }
  }

  .form-element-column-wide {
    flex: 120%;

    &:not(:last-of-type) {
      margin-right: rem(10);
    }

    &:last-of-type {
      margin-left: rem(10);
    }
  }
}
.tooltip .tooltip-text {
  visibility: hidden;
  width: rem(200);
  height: fit-content;
  background-color: white;
  color: var(--blue_dark);
  text-align: right;
  border-radius: 6px;
  padding: rem(8);
  right: rem(20);
  top: rem(60);
  font-size: 14px;
  line-height: 18px;

  /* Position the tooltip */
  position: absolute;
  z-index: 10;

  p {
    white-space: pre-wrap;
    height: 100%;
    text-align: left;
  }
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

/** **/
</style>
