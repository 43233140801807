<template>
  <div :class="{'active': active}" class="menu-item">
    <!-- Active indicator -->
    <div class="active-bar"></div>

    <!-- Menu icon -->
    <InlineSvg class="icon" :src="icon" />

    <!-- Menu text -->
    <p class="text">{{ text }}</p>
    <InlineSvg v-if="meta.external" class="external-icon" :src="require('../../../assets/icons/icn_externallink.svg')" />
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'MenuLink',
  components: {
    InlineSvg
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.menu-item {
  @include hover-active-pointer();
  align-items: center;
  display: flex;
  height: rem(50);

  .icon {
    height: rem(26);
    margin-left: rem(28);
    margin-right: rem(20);
    width: rem(26);
    :deep g, :deep g path {
      fill: var(--purple_light);
    }
  }

  .external-icon {
    width: rem(19);
    height: rem(19);
    margin-left: rem(10);
    :deep g, :deep g path {
      fill: var(--purple_light);
    }
  }

  .text {
    color: var(--purple_light);
    font-size: rem(16);
    letter-spacing: 0;
    line-height: rem(19);
  }

  &:not(:last-child) {
    margin-bottom: rem(15);
  }

  transition: color 0.15s;

  &:hover {
    .icon {
      color: var(--pink_main);
      fill: var(--pink_main);
      :deep g, :deep g path {
        fill: var(--pink_main);
      }
    }
  }
}

/** Active styles **/
.active-bar {
  background-color: var(--pink_main);
  border-radius: 0 rem(3) rem(3) 0;
  display: none;
  height: 100%;
  width: rem(6);
}

.active {
  .text {
    color: var(--pink_main);
    font-weight: 500;
  }

  .icon {
    margin-left: rem(22);
    fill: var(--pink_main);
    :deep g, :deep g path {
      fill: var(--pink_main)
    }
  }

  .active-bar {
    display: block;
  }
}
</style>
