export default class MenuItem {
  /**
   * @param id {number}
   * @param itemName {string}
   * @param icon {string}
   * @param routeName {string}
   * @param assocRouteNames {string[]}
   */
  constructor(id, icon, itemName, routeName, assocRouteNames = [], meta = {}) {
    this.id = id
    this.icon = icon
    this.itemName = itemName
    this.routeName = routeName
    this.assocRouteNames = assocRouteNames
    this.meta = {}
  }

  addMeta(newMeta = {}) {
    this.meta = newMeta
  }

  changeDisplayName(newName) {
    this.itemName = newName
  }
}
