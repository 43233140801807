export default class Menu {
  /**
   * Represents a menu.
   * @param {String} id - The id of the subcategory.
   * @param {String} subcategory - The name of the subcategory.
   * @param {Array.<MenuItem>} items - The menu items of the subcategory.
   */
  constructor(id, subcategory, items) {
    // The menu can also have no subcategories. In that case, you can simply pass an empty string as the name.
    this.id = id
    this.subcategory = subcategory
    this.items = items
  }

  static create(id, subcategory, items) {
    return new Menu(id, subcategory, items)
  }
}
