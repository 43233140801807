<template>
  <div class="sidebar-wrapper scrollable">
    <div>
      <!-- Sidebar logo -->
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <div class="sidebar-logo-wrapper">
        <img :src="sidebarLogo" class="sidebar-logo" :class="{ 'helpdesk-logo': route.meta.platform === 'helpdesk', 'cms-logo': route.meta.platform === 'cms' }" alt="MediaMasters logo"/>
      </div>

      <div v-if="teacherProfile && teacherProfile.firstName" class="login-details">
        <p style="margin-bottom: 6px;"><span class="sidebar__bold-header">Ingelogd als: </span><br/>{{ teacherProfile.firstName }} {{ teacherProfile.lastName }}</p>
        <p v-if="teacherProfile.schoolName" ><span class="sidebar__bold-header">Gelinkte school: </span><br/>{{ teacherProfile.schoolName }}</p>
        <p v-if="!teacherProfile.isApproved" class="sidebar__bold-header" style="margin-top: 6px;">Account niet geverifieerd</p>
      </div>

      <!-- The sidebar menu has sub-categories -->
      <div v-for="category in menuItems" :key="category.id" class="menu-categories">
        <!-- Sub-category name -->
        <p v-if="category.subcategory" class="subcategory-name">{{ category.subcategory }}</p>

        <!-- Menu items of the subcategory -->
        <MenuLink v-for="item in category.items.filter(value => shouldShow(value))"
                  :key="item.id"
                  :text="item.itemName"
                  :active="selectedMenuId === item.id"
                  :meta="item.meta"
                  :icon="item.icon"
                  @click="onItemClick(item)"/>
      </div>
    </div>

    <!-- Logout button -->
    <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
    <div class="logout-button" @click="displayLogoutWarning()">
      <img src="../../assets/icons/icn_logout.svg" class="icon" alt="Logout"/>
      <span class="text">{{ $t('LOGOUT.TITLE') }}</span>
    </div>

    <!-- Logout Popup -->
    <BasicModal v-if="activeBasicModal === MODAL_NAMES.LOGOUT"/>
  </div>
</template>

<script>
import MenuLink from '@/components/elements/menu/MenuLink'
import { useStore } from 'vuex'
import {
  RESET_ACTIVE_BASIC_MODAL,
  RESET_BASIC_MODAL_DATA,
  SET_BASIC_MODAL_DATA,
  SET_SELECTED_MENU
} from '@/store/mutations'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { useRoute, useRouter } from 'vue-router'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { computed } from '@vue/reactivity'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { LOGOUT } from '@/store/modules/auth/actions'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import { watch } from '@vue/runtime-core'
// import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
// import { USER_TYPES } from '@/models/User'
import { GET_SCHOOL_CLASSES } from '@/store/modules/teacherPortal/actions'
import { SET_SELECTED_CLASS } from '@/store/modules/teacherPortal/mutations.js'
import { PASSED_WEEK_STATUS, ACTIVE_WEEK_STATUS } from '@/utils/data/weekStatus'

export default {
  name: 'SidebarMenu',
  components: {
    BasicModal,
    MenuLink
  },
  props: {
    menuItems: {
      type: Array,
      required: true
    },
    sidebarLogo: {
      type: String,
      default: '',
      // required: true
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const activeBasicModal = computed(() => store.getters.getActiveBasicModal)
    const teacherProfile = computed(() => store.getters.getTeacherProfile)

    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    // todo: remove fetching of classes in the side menu
    const classes = computed(() => store.getters.getSchoolClasses)
    const myClasses = computed(() => classes.value ? classes.value.filter(aClass => aClass.teachers.some(teacher => teacher.id === teacherProfile.value.teacherId)) : [])
    const noClasses = computed(() => myClasses.value.length === 0)

    const globalWeekData = computed(() => store.getters.getWeekData)
    const shouldShowRanking = computed(() => globalWeekData.value?.status === PASSED_WEEK_STATUS || (globalWeekData.value?.status === ACTIVE_WEEK_STATUS && classes.value.length > 1))

    // const isPostWeek = computed(() => globalWeekData.value && (globalWeekData.value.status === PASSED_WEEK_STATUS || globalWeekData.value.status === PASSED_PRE_RANKING_STATUS))
    // const weekActive = computed(() => globalWeekData.value && globalWeekData.value.status === ACTIVE_WEEK_STATUS)
    // const preWeek = computed(() => globalWeekData.value && globalWeekData.value.status === UPCOMING_WEEK_STATUS)

    if (route.meta.platform === 'teacherPortal') {
      store.dispatch(GET_SCHOOL_CLASSES)
    }
    initializeMenu()

    /** Sidebar setup **/
    function initializeMenu() {
      // Find the menu item that's supposed to be our current menu item.
      const currentMenuItem = matchMenuItemByRouteName(route.name)
      if (currentMenuItem) {
      // Set it as selected.
        setSelectedMenu(currentMenuItem.id)
      }
    }

    /**
     * Method that matches a menu item against a route name.
     * The criteria used for matching are:
     * that the menu item has the route name set as its <code>routeName</code> property
     * OR
     * that the menu item has the route name included in its array of associative route names.
     */
    function matchMenuItemByRouteName(routeName) {
      let result = false
      // Loop through the menu items (subcategories)
      props.menuItems.map(subcategory => {
        // Check if the subcategory contains the current route name.
        if (!result) {
          result = subcategory.items.find(menuItem => {
            const isEqual = menuItem.routeName === routeName
            const isAssoc = !!menuItem.assocRouteNames.find(name => name === routeName)
            return isEqual || isAssoc
          })
        }
      })

      return result
    }

    /** Menu items **/
    const selectedMenuId = computed(() => store.getters.getSelectedMenuId)


    watch(() => router.currentRoute.value,
      (curr, prev) => {
        if (curr.name !== prev.name) {
          const found = matchMenuItemByRouteName(curr.name)
          if (found) {
            setSelectedMenu(found.id)
          }
        }
      })

    function setSelectedMenu(id) {
      store.commit(SET_SELECTED_MENU, id)
    }

    function onItemClick(menuItem) {
      if (menuItem.meta.external) {
        window.open(menuItem.meta.externalLink, '_blank').focus()
        return
      }
      setSelectedMenu(menuItem.id)
      router.push({ name: menuItem.routeName })
    }

    /** Logout **/
    function displayLogoutWarning() {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.LOGOUT,
          cancelCallback: null,
          executeCallback: beforeLogout
        },
        activeModal: MODAL_NAMES.LOGOUT
      }
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function beforeLogout() {
      // Get the active platform, so we are able to redirect the user to the correct login page.
      let platform = route.meta.platform

      logout()
        .then(response => {
          if (response) {
            store.commit(RESET_BASIC_MODAL_DATA)
            store.commit(RESET_ACTIVE_BASIC_MODAL)

            // Clear if going to teacher portal
            if (platform === 'teacherPortal') {
              platform = ''
              store.commit(SET_SELECTED_CLASS, null)
            }
            // router.push({ name: ROUTE_NAMES_AUTH.LOGIN, params: { userType: platform } })
          }
        })
        .finally(() => {
          localStorage.removeItem('access_token')
          router.push({ name: ROUTE_NAMES_AUTH.LOGIN, params: { userType: platform } })
        })
    }

    function logout() {
      return store.dispatch(LOGOUT)
    }

    function checkMetaTeacherPortal(value) {
      if (bothTeacherAndProfessional(value) === false) {
        return false
      }
      if (value.meta.hideIfNoClasses && noClasses.value) {
        return false
      }
      return true
    }

    function checkMetaProfessional(value) {
      if (!bothTeacherAndProfessional(value)) {
        return false
      }
      return true
    }

    function bothTeacherAndProfessional(value) {
      if (value.meta.preRegistration && teacherProfile.value.isApproved) {
        return false
      }
      if (value.meta.requireRegistration && !teacherProfile.value.isApproved) {
        return false
      }
      return true
    }

    function checkMetaHelpdesk(value) {
      return true
    }

    function checkMetaCMS(value) {
      return true
    }

    function shouldShow(value) {
      if (value.meta.postRanking && !shouldShowRanking.value) {
        return false
      }

      switch (route.meta.platform) {
        case 'teacherPortal':
          // console.log('checked this one')
          return checkMetaTeacherPortal(value)
        case 'professional':
          return checkMetaProfessional(value)
        case 'helpdesk':
          return checkMetaHelpdesk(value)
        case 'cms':
          return checkMetaCMS(value)
        default:
          return true
      }
    }

    return {
      MODAL_NAMES,
      activeBasicModal,
      teacherProfile,
      route,

      /** Logout **/
      displayLogoutWarning,

      /** Menu items **/
      selectedMenuId,
      onItemClick,
      shouldShow,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

$sidebar-background: #E9EEF7;

.sidebar-wrapper {
  background-color: $sidebar-background;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  text-align: center;
  width: $sidebar-width;
  flex-shrink: 0;
}

.sidebar-logo-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .sidebar-logo {
    align-self: center;
    height: auto;
    margin-bottom: rem(30);
    margin-top: rem(26);
    width: rem(160);
  }

  .sidebar-logo.helpdesk-logo {
    width: rem(200);
  }

  .sidebar-logo.cms-logo {
    width: rem(180);
  }
}


.subcategory-name {
  color: var(--purple_light);
  font-size: rem(18);
  font-weight: bold;
  letter-spacing: 0;
  line-height: rem(14);
  margin-bottom: rem(20);
  margin-top: rem(56);
  opacity: 0.6;
  padding-left: rem(30);
  text-align: left;
}

.logout-button {
  @include hover-active-pointer();
  align-items: center;
  display: flex;
  margin: rem(50) 0 rem(37) rem(28);

  .text {
    color: var(--purple_light);
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.1875rem;
  }

  .icon {
    height: rem(28);
    margin-right: rem(18);
    width: rem(28);
  }
}

.sidebar__bold-header {
  font-weight: bold;
}

.login-details{
  padding: rem(0) rem(10);
  word-wrap: break-word
}
</style>
